import ImageExtractor from "utils/Extractors/images";
import { PATH } from "constants/Description";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

/**
 * @param {*} room
 * @param {*} config
 * @returns the room id as path for the routing
 */

export const mapRooms = (stores) => {
    return stores.map((store) => store.runtime.pages.rooms).flat();
};

export const mapActivities = (stores) => {
    return stores
        .map(
            (store) =>
                store.runtime.pages.home.components.outdoorActivities
                    ?.activitiesScenarios
        )
        .flat();
};

export const getRoom = (room, config) => {
    const rooms = mapRooms(config.stores);
    return rooms.find((item) => item.id === room);
};

export const getActivity = (act, config) => {
    const activities = mapActivities(config.stores);
    return activities.find((item) => item.href === act);
};

export const isOutdoor = (room, config) => {
    const activeRoom = getRoom(room, config);
    return activeRoom.outdoor;
};

export function ScrollToTopOnPathChange() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}

export const playersImg = ImageExtractor(PATH.players);
export const timeImg = ImageExtractor(PATH.time);
export const categoryImg = ImageExtractor(PATH.category);
export const ageImg = (Room) => {
    return require(`assets/default/pages/Room/ages/${Room.age}.svg`);
};

export const pregnantImg = () => {
    const imageModule = require(`assets/default/pages/Room/pregnant.svg`);
    return imageModule.default || imageModule;
};

export const heartImg = () => {
    const imageModule = require(`assets/default/pages/Room/heart.svg`);
    return imageModule.default || imageModule;
};

export const lowLightImg = () => {
    const imageModule = require(`assets/default/pages/Room/lamp-low.png`);
    return imageModule.default || imageModule;
};

export const medLightImg = () => {
    const imageModule = require(`assets/default/pages/Room/lamp-medium.png`);
    return imageModule.default || imageModule;
};

export const skullImg = () => {
    const imageModule = require(`assets/default/pages/Room/skull.png`);
    return imageModule.default || imageModule;
};

export const adultImg = () => {
    const imageModule = require(`assets/default/pages/Room/adult-supervision.svg`);
    return imageModule.default || imageModule;
};

export const snowImg = () => {
    const imageModule = require(`assets/default/pages/Room/snowflake.svg`);
    return imageModule.default || imageModule;
};

export const mobilityImg = () => {
    const imageModule = require(`assets/default/pages/Room/mobility.png`);
    return imageModule.default || imageModule;
};

export const questionMarkSvg = () => {
    const imageModule = require(`assets/default/pages/Room/question-mark.svg`);
    return imageModule.default || imageModule;
};

export const langRestrictImg = () => {
    const imageModule = require(`assets/default/pages/Room/worldwide.png`);
    return imageModule.default || imageModule;
};

export const difficultyImg = (Room) => {
    return ImageExtractor(
        `default/pages/Room/difficulties/${Room.difficulty_svg}.svg`
    );
};

export const descriptionClass = (selectedLang) => {
    return classNames({
        arabic_direction:
            selectedLang === "sa" ||
            selectedLang === "ar" ||
            selectedLang === "kw",
    });
};
