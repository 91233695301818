import PropTypes from "prop-types";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getCaptionsContainer } from "helpers/HomeCarousel.helpers";
import config from "config/main-config.json";
import styles from "./styles.module.css";
import CustomModal from "components/CustomModal/CustomModal";
import AirConditions from "./AirConditions";
import OpeningHours from "components/OpeningHours/OpeningHours";
import { useOutsideClick } from "helpers/general.helpers";
import { twMerge } from "tailwind-merge";

const CarouselItem = ({ item }) => {
    const { t } = useTranslation();
    const hasImage = (item) => {
        return !!item.captions.image;
    };
    const [isOpeningHoursShown, setIsOpeningHoursShown] = useState(false);
    const { isAvailable } = config;
    const isComingSoon = item.captions.button?.text === "coming_soon";
    const buttonValue =
        isAvailable && isComingSoon ? "book_now" : item.captions.button?.text;
    const buttonHref =
        isComingSoon && isAvailable ? "#Scenarios" : item.captions.button?.href;
    const { captionButtons } = item.captions;
    const { carouselLogos } = item.captions;
    const customHead = item.captions.customHead ?? false;
    const hasAirConditionRooms = item.captions.hasAirConditionRooms ?? false;
    const { col_buttons } = item.captions;
    const { customDisclaimer, operationHoursModal, ratingImage } =
        item.captions;
    const openingHoursRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (
            openingHoursRef.current &&
            !openingHoursRef.current.contains(event.target)
        ) {
            setIsOpeningHoursShown(false);
        }
    };
    const selectedLang = localStorage.getItem("language");
    useOutsideClick(handleOutsideClick);

    return (
        <div
            className='my-[0] mx-auto text-white box-border flex content-center z-[100]'
            ref={openingHoursRef}>
            <div
                className={twMerge(
                    `absolute w-[100%] bottom-[10%] my-[0] mx-auto z-[10]`,
                    `pt-[3.75rem] pb-[1.25rem] text-center`,
                    `top-[5%] mdl:bottom-[8%] mdl:py-[1.25rem]`,
                    `${styles.captions}`
                )}>
                {customHead && (
                    <h1 className='text-[3rem] mt-[-4rem] mb-[3rem]'>
                        {customHead}
                    </h1>
                )}
                {hasAirConditionRooms.offSet === "top" && (
                    <div className='translate-y-[-10%]'>
                        <AirConditions
                            hasAirConditionRooms={hasAirConditionRooms}
                        />
                    </div>
                )}
                <div className='text-center'>{getCaptionsContainer(item)}</div>
                <div
                    className={`top-[15%] relative mxl:top-[10%] sxl:top-[15%] xs:top-[5%] smm:top-[5%] ${
                        !hasImage(item) ? "mt-[30%]" : ""
                    } ${
                        item.captions.text[2] === "company's events."
                            ? "md:mt-[80%]"
                            : ""
                    }`}>
                    <div
                        className={twMerge(
                            "bottom-0 box-border text-white [font-family:'Raleway']",
                            "leading-[30px] text-[27px] left-0",
                            "tracking-[-2px] [list-style:outside_none_none]",
                            "[margin:0px_0px_1.5rem] mb-[1rem] mt-0 relative",
                            "right-0 text-center top-0 md:text-[17px]",
                            "smm:tracking-[-1px] md:[margin:0px_0px_2rem] md:leading-[1.3]"
                        )}>
                        <ul
                            className={`list-none cs:leading-[17px] ${
                                item.captions.type === "text"
                                    ? "p-[unset] mt-[-34%]"
                                    : "contents leading-[2.5rem] mt-[-5%]"
                            }`}>
                            {item.captions.text.map((item, index) => {
                                return (
                                    <li
                                        className={item
                                            .replace(/[.,'’!\s]/g, "")
                                            .substring(0, 35)}
                                        key={index}>
                                        {t(item)}
                                    </li>
                                );
                            })}
                        </ul>
                        {hasAirConditionRooms.offSet === "bottom" && (
                            <AirConditions
                                hasAirConditionRooms={hasAirConditionRooms}
                            />
                        )}
                        {customDisclaimer && (
                            <p
                                className={twMerge(
                                    "text-[1.3rem] text-center font-thin tracking-[-1px]",
                                    "h-[3rem] inline-flex relative ",
                                    "!m-0 md:text-[0.9rem] md:h-[0]"
                                )}>
                                {t(customDisclaimer.text)}
                            </p>
                        )}
                    </div>
                    {captionButtons && (
                        <div className='flex justify-center items-center md:flex-col'>
                            {captionButtons.map((buttonItem, index) => (
                                <>
                                    {buttonItem.href !== "isAmsterdamModal" && (
                                        <a
                                            key={index}
                                            href={buttonItem.href}
                                            className={twMerge(
                                                "rounded-[30px] border-[3px] border-solid",
                                                "border-primary-red text-white font-bold",
                                                "text-[1rem] p-[1rem] m-[1rem] cursor-pointer",
                                                "hover:bg-white hover:text-primary-red"
                                            )}>
                                            {t(buttonItem.text)}
                                        </a>
                                    )}
                                    {buttonItem.href === "isAmsterdamModal" && (
                                        <div className='flex justify-center items-center md:flex-col z-[9998]'>
                                            <a
                                                href={buttonItem.modalIFrame}
                                                className={twMerge(
                                                    "rounded-[30px] !mt-[0.5%] border-[3px] border-solid",
                                                    "border-primary-red text-white font-bold",
                                                    "text-[1rem] p-[1rem] m-[1rem] cursor-pointer",
                                                    "hover:bg-white hover:text-primary-red"
                                                )}>
                                                {t(buttonItem.text)}
                                            </a>
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                    )}
                    {ratingImage && (
                        <img
                            className='[display:unset] mt-[2%] md:max-w-[80%]'
                            alt={ratingImage}
                            src={require(`assets/homeCarousel/${ratingImage}`)}
                        />
                    )}
                    {carouselLogos && (
                        <div className='flex flex-wrap flex-row w-[50%] xl:w-[100%] relative mt-20 h-auto mx-auto justify-center'>
                            {carouselLogos.map((logo, logoIndex) => (
                                <img
                                    key={logoIndex}
                                    src={require(`assets/homeCarousel/${logo}`)}
                                    alt={`carousel-logo-${logoIndex}`}
                                    className='w-[8rem] mdl:w-[4rem] sm:w-[3rem] xs:w-[2rem] mx-[10%] !object-contain'
                                />
                            ))}
                        </div>
                    )}
                    {col_buttons && (
                        <div className='inline-flex justify-center flex-col'>
                            {col_buttons.map(
                                (colButtonItem, colButtonIndex) => (
                                    <>
                                        <a
                                            key={colButtonIndex}
                                            href={colButtonItem.href}
                                            className={twMerge(
                                                "rounded-[30px] bg-white mt-[20px] text-primary-red",
                                                "font-black py-[0.3rem] px-[1.5rem]",
                                                "text-[19px] hover:text-white hover:bg-primary-red"
                                            )}
                                            onClick={
                                                colButtonItem.text ===
                                                "Opening Hours >"
                                                    ? () =>
                                                          setIsOpeningHoursShown(
                                                              !isOpeningHoursShown
                                                          )
                                                    : undefined
                                            }>
                                            {colButtonItem.text !==
                                            "Opening Hours >"
                                                ? t(colButtonItem.text)
                                                : `${t("Opening Hours")}${
                                                      isOpeningHoursShown
                                                          ? "∨"
                                                          : ">"
                                                  }`}
                                        </a>
                                    </>
                                )
                            )}
                        </div>
                    )}
                    {item.captions.button && (
                        <a
                            href={buttonHref}
                            className={twMerge(
                                "rounded-[30px] bg-white mt-[20px] text-primary-red",
                                " font-black py-[0.5rem] px-[1.5rem] text-[19px]",
                                "hover:text-white hover:bg-primary-red"
                            )}>
                            {t(buttonValue)}
                        </a>
                    )}
                </div>
                {item.captions.extraText && (
                    <p
                        className={twMerge(
                            "text-[1.5rem] mt-[7rem] tracking-[unset]",
                            "mdl:text-[1.3rem] mdl:tracking-[0.1px]",
                            "sm:text-[1.1rem] sm:w-[65%] sm:inline-flex"
                        )}>
                        {t(item.captions.extraText)}
                    </p>
                )}
                {operationHoursModal && (
                    <CustomModal
                        data={operationHoursModal}
                        classNames={twMerge(
                            "button-toggle !text-primary-red hover:!text-white",
                            `hover:bg-primary-red carousel-custom-modal-${selectedLang}`
                        )}
                    />
                )}
                {isOpeningHoursShown && (
                    <div>
                        <OpeningHours
                            type='carousel'
                            isFindUs={false}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

CarouselItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number,
    currentSlide: PropTypes.number,
    nextSlideToLoad: PropTypes.number,
    setOpenButtonModal: PropTypes.func,
};

export default CarouselItem;
