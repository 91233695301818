import ImageExtractor from "utils/Extractors/images";
import config from "config/main-config.json";

let createLocation = (imagePaths) => {
    return imagePaths.map((imagePath) => ({
        image: ImageExtractor(imagePath),
    }));
};

const roomCarousels = config.stores.flatMap((store) =>
    store.runtime.pages.rooms.map((room) => ({
        id: room.id,
        carouselImagesRoute: room.carouselImagesRoute,
    }))
);

export const locationToImages = {};
let customCarousel = "";

for (let { id, carouselImagesRoute } of roomCarousels) {
    const numImages = () => {
        const number = [];

        for (let store of config.stores) {
            const roomObj = store.runtime.pages.rooms.find(
                ({ id: roomId }) => roomId === id
            );
            if (roomObj) {
                const { carousel_imgs_number, hasCustomCarousel } = roomObj;

                customCarousel = hasCustomCarousel ?? "";
                number.push(parseInt(carousel_imgs_number));
            }
        }

        return number.length === 1 ? number[0] : number;
    };

    const images = [];
    const totalImages = numImages();

    for (let i = 1; i <= totalImages; i++) {
        const imagePath = customCarousel
            ? `roomscarousel/${carouselImagesRoute}/${carouselImagesRoute}-${customCarousel}-${i}.webp`
            : `roomscarousel/${carouselImagesRoute}/${carouselImagesRoute}-${i}.webp`;

        images.push(imagePath);
    }

    locationToImages[`/rooms/${id}`] = createLocation(images);
}

export const homeCarouselData =
    config.runtime.pages.home.components.carousel.map((item) => {
        return {
            image: ImageExtractor(`homeCarousel/${item.image}`),
            url: item.url,
            hasCustomHeight: item.hasCustomHeight,
            captions: { ...item.captions },
        };
    });
